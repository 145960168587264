<template>
  <v-sheet
    rounded="xl"
    class="text-start pa-6 py-8"
    maxWidth="586px"
    :width="$vuetify.breakpoint.smAndUp ? '586px' : ''"
  >
    <div class="text-body-1 mb-6 font-weight-medium">
      <v-icon class="mr-2" @click="$router.go(-1)">mdi-arrow-left</v-icon> My Token Locker
    </div>
    <v-sheet color="primary secondary pa-6 mb-6" rounded="lg">
      <v-row class="ma-0 mb-4">
        <v-col cols="12" sm="6" class="pa-0">
          <div class="d-flex align-center mb-0">
            <v-img max-width="28px" contain class="mr-2" :src="require(`@/assets/binance.svg`)" />
            <div class="text-medium font-weight-regular">{{ userTokenInfo.name }} / {{ userTokenInfo.symbol }}</div>
          </div>
        </v-col>
        <v-col cols="12" sm="6" class="pa-0">
          <div class="text-body-1" :class="$vuetify.breakpoint.smAndUp ? 'text-end' : ''">
            {{ userTokenInfo.decimals }} decimals
          </div>
        </v-col>
      </v-row>

      <div class="grey--text text-center text-body-1">Balance</div>
      <h3 class="text-center font-weight-regular">{{ userTokenInfo.balance }} {{ userTokenInfo.name }}</h3>
    </v-sheet>

    <v-row class="mb-6 ma-0">
      <v-col cols="12" sm="6" class="pa-0">
        <div class="d-flex justify-center">
          <v-btn
            class="text-body-1"
            rounded
            text
            block
            :color="isNewLockTab ? 'primary' : 'grey'"
            @click="isNewLockTab = true"
            ><v-icon class="mr-2">mdi-lock-plus-outline</v-icon> New lock</v-btn
          >
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="pa-0">
        <div class="d-flex justify-center">
          <v-btn
            class="text-body-1"
            rounded
            text
            :color="!isNewLockTab ? 'primary' : 'grey'"
            @click="isNewLockTab = false"
            ><v-icon class="mr-2">mdi-lock-outline</v-icon>My {{ userTokenInfo.name }} locks ({{
              userTokenLocks.length
            }})</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <add-lock-tab v-show="isNewLockTab" @lockDone="handleLockDone" :locks="tokenLocks" ref="addLockTab"></add-lock-tab>
    <user-locks-tab v-show="!isNewLockTab" />
  </v-sheet>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import AddLockTab from '@/modules/lockTokens/AddLockTab.vue'
import UserLocksTab from '@/modules/lockTokens/UserLocksTab.vue'
export default {
  components: {
    AddLockTab,
    UserLocksTab,
  },
  data() {
    return {
      state: true,
      isNewLockTab: true,
      tokenAddress: '',
    }
  },
  computed: {
    ...mapState('auth', ['account', 'lockHandler']),
    ...mapState('lockTokens', ['userTokenInfo', 'tokenLocks', 'userTokenLocks']),
  },
  methods: {
    ...mapActions('lockTokens', ['fetchUserTokenInfo', 'fetchUserTokenLocks', 'fetchAllTokenLocks']),
    async handleFetchData() {
      try {
        await this.fetchUserTokenInfo({
          lockHandler: this.lockHandler,
          tokenAddress: this.tokenAddress,
        })
        await this.fetchUserTokenLocks(this.lockHandler)
      } catch (e) {
        this.$alert.error(e.message)
      }
    },
    async handleLockDone() {
      try {
        await this.handleFetchData()
        this.fetchAllTokenLocks(this.lockHandler)
      } catch (e) {
        console.error(e)
      }
    },
  },
  async created() {
    this.tokenAddress = this.$route.params.tokenAddr
    try {
      this.$loading.active = true
      await this.handleFetchData()
      this.$loading.active = false
      this.fetchAllTokenLocks(this.lockHandler)
    } catch (e) {
      console.error(e)
      this.$loading.active = false
    }
  },
}
</script>
