<template>
  <v-dialog v-model="dialog" max-width="586px" @keydown.esc="cancel">
    <!-- <v-sheet rounded="xl" class="pa-6 text-center" maxWidth="586px"> -->
    <v-form ref="addForm" class="pa-6">
      <div class="d-flex justify-space-between mb-8">
        <div
          class="align-self-center font-weight-medium"
          :class="$vuetify.breakpoint.smAndUp ? 'text-h6' : 'text-body-1'"
        >
          Add Lock
        </div>
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </div>
      <div :class="$vuetify.breakpoint.smAndUp ? 'text-h6' : 'text-body-1'">Who can withdraw the tokens?</div>
      <v-row class="mb-5 ma-0">
        <v-col cols="12" sm="5" class="pa-0">
          <div class="d-flex" :class="$vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'">
            <v-btn
              class="text-body-1"
              rounded
              text
              :color="isSelfUnlock ? 'primary' : 'grey'"
              @click="isSelfUnlock = true"
              >Me</v-btn
            >
          </div>
        </v-col>
        <v-col cols="12" sm="7" class="pa-0">
          <div class="d-flex" :class="$vuetify.breakpoint.smAndUp ? 'justify-right' : 'justify-center'">
            <v-btn
              class="text-body-1"
              rounded
              text
              :color="!isSelfUnlock ? 'primary' : 'grey'"
              @click="isSelfUnlock = false"
              >Someone else</v-btn
            >
          </div>
        </v-col>
      </v-row>

      <div v-if="!isSelfUnlock">
        <div class="text-caption grey--text mb-1">
          *Allow to withdraw these tokens before the unlock date when this condition is met.
        </div>
        <v-sheet rounded="xl" class="primary secondary lighten-3 pa-6 pt-6 pb-0">
          <v-text-field
            label="Contract address"
            outlined
            rounded
            dense
            v-model="unlockerAddress"
            :rules="[$rules.required, $rules.contractAddress]"
          />
        </v-sheet>
      </div>

      <v-divider class="my-6" />
      <div class="mb-2" :class="$vuetify.breakpoint.smAndUp ? 'text-h6' : 'text-body-1'">Lock how many tokens ?</div>
      <v-sheet rounded="xl" class="pa-6">
        <p class="text-caption text-right">Balance: {{ maxRemainDebit }}</p>
        <v-row class="text-body-1">
          <v-col cols="12" sm="6" :order="$vuetify.breakpoint.smAndUp ? '1' : '2'" class="pb-0">
            <v-text-field
              outlined
              v-model="tokenAmountInput"
              rounded
              dense
              :rules="[$rules.required, rules.maxToken, rules.minToken]"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            :order="$vuetify.breakpoint.smAndUp ? '2' : '1'"
            class="d-flex align-center justify-end pt-0"
          >
            <v-img max-width="24" max-height="24" contain :src="require(`@/assets/binance.svg`)" />
            <span class="mx-3 text-h6">{{ userTokenInfo.name }}</span>
            <v-btn class="primary white--text" color="white" small rounded max-height="24" @click="onMultipleChange(1)"
              >MAX</v-btn
            >
          </v-col>
          <v-col cols="12" order="3" class="d-flex flex-wrap pt-0">
            <v-btn text class="text-body-1" @click="onMultipleChange(0.25)">25%</v-btn>
            <v-btn text class="text-body-1" @click="onMultipleChange(0.5)">50%</v-btn>
            <v-btn text class="text-body-1" @click="onMultipleChange(0.75)">75%</v-btn>
            <v-btn text class="text-body-1" @click="onMultipleChange(1)">100%</v-btn>
          </v-col>
        </v-row>
      </v-sheet>
      <v-divider class="my-6" />

      <v-row class="mb-2 ma-0">
        <v-col cols="12" sm="6" class="pa-0">
          <div class="d-flex" :class="$vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'">
            <v-btn
              class="text-body-1"
              rounded
              text
              :color="isUnlockOnDate ? 'primary' : 'grey'"
              @click="isUnlockOnDate = true"
              >Unlock on date</v-btn
            >
          </div>
        </v-col>
        <v-col cols="12" sm="6" class="pa-0">
          <div class="d-flex" :class="$vuetify.breakpoint.smAndUp ? 'justify-right' : 'justify-center'">
            <v-btn
              class="text-body-1"
              rounded
              text
              :color="!isUnlockOnDate ? 'primary' : 'grey'"
              @click="isUnlockOnDate = false"
              >Unlock overtime</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <unlock-on-date v-if="isUnlockOnDate" ref="unlockOnDate" />
      <unlock-overtime v-else ref="unlockOvertime" />
      <div class="d-flex justify-end mt-6">
        <v-btn class="primary px-10" active-class rounded @click="addLock">Add</v-btn>
      </div>
    </v-form>
  </v-dialog>
</template>
<script>
import fixedNumberHelper from '@/helpers/fixedNumberHelper.js'

import { mapState, mapGetters } from 'vuex'
import UnlockOnDate from '@/modules/addLock/UnlockOnDate'
import UnlockOvertime from '@/modules/addLock/UnlockOvertime'

export default {
  components: { UnlockOnDate, UnlockOvertime },
  props: {
    state: Boolean,
  },
  data() {
    return {
      dialog: false,
      isSelfUnlock: true,
      isUnlockOnDate: true,
      unlockerAddress: '',
      tokenAmountInput: '0.0',
      rules: {
        maxToken: (v) => {
          if (!new RegExp(/^\d+(\.\d{1,18})?$/).test(v)) return 'Invalid value'
          else
            return (
              fixedNumberHelper.lte(fixedNumberHelper.from(v), this.maxRemainDebit) ||
              `You do not have enough ${this.userTokenInfo.name} for this transaction.`
            )
        },
        minToken: (v) => {
          if (!new RegExp(/^\d+(\.\d{1,18})?$/).test(v)) return 'Invalid value'
          else
            return (
              fixedNumberHelper.gte(fixedNumberHelper.from(v), this.minToken) ||
              `Must to more than ${this.minToken.toString()}`
            )
        },
      },
    }
  },
  computed: {
    ...mapState('auth', ['account']),
    ...mapState('lockTokens', ['userTokenInfo', 'minToken']),
    ...mapGetters('lockTokens', ['totalDebit', 'maxDebit']),

    maxRemainDebit() {
      return fixedNumberHelper.subUnsafe(this.maxDebit, this.totalDebit)
    },
  },

  methods: {
    onMultipleChange(multiple) {
      this.tokenAmountInput = fixedNumberHelper
        .mulUnsafe(this.maxRemainDebit, fixedNumberHelper.from(multiple))
        .toString()
    },
    async addLock() {
      if (!this.$refs.addForm.validate()) return
      const dateParams = this.isUnlockOnDate ? this.$refs.unlockOnDate.getData() : this.$refs.unlockOvertime.getData()
      this.$emit('onAddLock', {
        owner: this.isSelfUnlock ? this.account : this.unlockerAddress.toLowerCase(),
        amount: fixedNumberHelper.from(this.tokenAmountInput),
        ...dateParams,
      })
      this.dialog = false
    },
    resetDefault() {
      this.$refs.addForm && this.$refs.addForm.reset()
      this.isSelfUnlock = true
      this.isUnlockOnDate = true
      this.unlockerAddress = ''
      this.tokenAmountInput = '0.0'
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    state(state) {
      this.dialog = true
      this.resetDefault()
    },
  },
}
</script>
