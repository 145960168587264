<template>
  <div class="text-center">
    <p class="grey--text mb-3">Start date</p>
    <v-sheet rounded="xl" class="primary secondary lighten-3 pa-6 text-start">
      <v-row>
        <v-col cols="12" md="6" class="pb-0">
          <p class="text-caption mb-2">Date</p>
          <v-text-field
            v-model="startEmissionDate"
            type="date"
            :rules="[$rules.required, rules.date]"
            dense
            outlined
            rounded
          />
        </v-col>
        <v-col cols="12" md="6" class="pb-0">
          <p class="text-caption mb-2">Hour</p>
          <v-text-field
            v-model="startEmissionHour"
            type="time"
            :rules="[$rules.required, rules.hour]"
            dense
            outlined
            rounded
          />
        </v-col>
      </v-row>
    </v-sheet>
    <p class="grey--text mt-4 mb-3">End date</p>
    <v-sheet rounded="xl" class="primary secondary lighten-3 pa-6 text-start">
      <v-row>
        <v-col cols="12" md="6" class="pb-0">
          <p class="text-caption mb-2">Date</p>
          <v-text-field
            v-model="endEmissionDate"
            type="date"
            :rules="[$rules.required, rules.date]"
            dense
            outlined
            rounded
          />
        </v-col>
        <v-col cols="12" md="6" class="pb-0">
          <p class="text-caption mb-2">Hour</p>
          <v-text-field
            v-model="endEmissionHour"
            type="time"
            :rules="[$rules.required, rules.hour]"
            dense
            outlined
            rounded
          />
        </v-col>
      </v-row>
    </v-sheet>
    <!-- <v-divider class="my-6" /> 
      <div v-if="false">
      <div class="font-weight-medium my-3 h6">Premature Unlock Condition</div>
      <div class="d-flex justify-center">
        <v-btn depressed rounded text class="text-body-1" @click="state = false" :color="!state ? 'primary' : ''"
          >No</v-btn
        >
        <v-btn depressed rounded text class="text-body-1" @click="state = true" :color="state ? 'primary' : ''"
          >Yes</v-btn
        >
      </div>
      <div v-if="state">
        <div class="text-caption grey--text mt-3 mb-1">
          *Allow to withdraw these tokens before the unlock date when this condition is met.
        </div>
        <v-sheet rounded="xl" class="primary secondary pa-6">
          <v-text-field label="Contract address" outlined rounded dense :rules="[$rules.required]" />
        </v-sheet>
      </div>
    </div> -->
  </div>
</template>
<script>
import { isEmpty } from 'lodash'
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  props: {
    editState: Boolean,
  },
  data() {
    return {
      startEmissionDate: '',
      startEmissionHour: '',
      endEmissionDate: '',
      endEmissionHour: '',
      rules: {
        date: () => {
          if (isEmpty(this.startEmissionDate) || isEmpty(this.endEmissionDate)) return true
          return (
            Date.parse(this.startEmissionDate) < Date.parse(this.endEmissionDate) || 'End date must be after start date'
          )
        },
        hour: () => {
          if (
            isEmpty(this.startEmissionDate) ||
            isEmpty(this.endEmissionDate) ||
            Date.parse(this.startEmissionDate) < Date.parse(this.endEmissionDate) ||
            isEmpty(this.startEmissionHour) ||
            isEmpty(this.endEmissionHour)
          )
            return true
          return this.startEmissionHour < this.endEmissionHour || 'End date must be after start date'
        },
      },
    }
  },
  methods: {
    getData() {
      return {
        startEmission: Date.parse(new Date(this.startEmissionDate + ' ' + this.startEmissionHour)) / 1000,
        endEmission: Date.parse(new Date(this.endEmissionDate + ' ' + this.endEmissionHour)) / 1000,
      }
    },
    resetDefault() {
      const startEmission = this.selectedTokenLock.startEmission
      if (startEmission !== 0) {
        const endEmission = this.selectedTokenLock.endEmission
        this.startEmissionDate = moment.unix(startEmission).format('YYYY-MM-DD')
        this.startEmissionHour = moment.unix(startEmission).format('HH:mm')
        this.endEmissionDate = moment.unix(endEmission).format('YYYY-MM-DD')
        this.endEmissionHour = moment.unix(endEmission).format('HH:mm')
      }
    },
  },
  computed: {
    ...mapState('lockTokens', ['selectedTokenLock']),
  },
  mounted() {},
  watch: {
    editState: {
      handler(editState) {
        if (editState) {
          this.resetDefault()
        }
      },
      immediate: true,
    },
  },
}
</script>

