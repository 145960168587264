<template>
  <div>
    <v-card outlined rounded="xl" style="background-color: transparent !important">
      <h4 class="py-3 pl-6 text-body-1">Lock ({{ tokenLocks ? tokenLocks.length : 0 }})</h4>
      <v-card outlined class="pa-6 lockCard" v-for="(item, index) in tokenLocks" :key="index">
        <v-row class="ma-0">
          <v-col cols="12" sm="9 pa-0 ">
            <div class="text-medium font-weight-regular">{{ item.amount }} {{ userTokenInfo.name }}</div>
          </v-col>
          <v-col cols="12" sm="3" class="pa-0">
            <div :class="$vuetify.breakpoint.smAndUp ? 'text-end' : 'text-start'">
              <v-icon small class="mr-3" @click="handleDoubleLock(item)">mdi-content-copy</v-icon>
              <v-icon small class="mr-3" @click="removeSelectedLock(index)">mdi-delete</v-icon>
              <v-icon small @click="openLockEdit(item)">mdi-pencil</v-icon>
            </div>
          </v-col>
        </v-row>
        <div class="text-caption grey--text font-weight-regular">
          Unclocked date:
          <span v-if="item.startEmission !== 0"> {{ item.startEmission | fullTime }} - </span>
          <span>{{ item.endEmission | fullTime }}</span>
        </div>

        <div class="pt-2">
          <v-btn rounded class="primary--text secondary lighten-1 font-weight-light" text small>
            Unlocker: {{ item.owner | shortOwmerAddress(account) }}</v-btn
          >
        </div>
      </v-card>
      <div class="text-center my-6">
        <v-btn rounded class="primary" small @click="addDialogState = !addDialogState" :disabled="locking"
          >Add a Lock</v-btn
        >
      </div>
      <v-divider style="opacity: 0.5" />
      <div class="text-center text-caption my-3">
        {{ totalDebit }}/ {{ userTokenInfo.balance }} {{ userTokenInfo.name }} total locked
      </div>
    </v-card>
    <v-card outlined class="mt-6" style="background-color: transparent !important" rounded="xl">
      <v-card-text class="text-body-1">
        <div class="d-flex justify-space-between">
          <div class="grey--text">Fee:</div>
          <div class="white--text">{{ tokenFeePercent }}%</div>
        </div>
        <v-row class="ma-0">
          <v-col cols="12" sm="3" class="pa-0">
            <div class="grey--text">Total debit:</div>
          </v-col>
          <v-col cols="12" sm="9" class="pa-0">
            <div class="white--text" :class="$vuetify.breakpoint.smAndUp ? 'text-end' : 'text-start'">
              {{ finalTotalDebit }} {{ userTokenInfo.name }}
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div class="text-center text-body-2 my-6 grey--text font-italic">
      <span class="red--text">*</span> Once tokens are locked they cannot be withdrawn under any circumstances until the
      timer has expired. Please ensure the parameters are correct, as they are final.
    </div>
    <v-row>
      <v-col cols="12" sm="6">
        <v-btn
          :loading="approving"
          block
          :class="!isApproved ? 'primary' : ''"
          rounded
          large
          @click="onApproveContract"
          :disabled="isApproved"
          >Approve</v-btn
        >
      </v-col>
      <v-col cols="12" sm="6" :loading="locking">
        <v-btn
          block
          :class="isApproved ? 'primary' : ''"
          large
          rounded
          @click="onLock"
          :disabled="!isApproved"
          :loading="locking"
          >Lock</v-btn
        >
      </v-col>
    </v-row>

    <LockTokenAddDialog :state="addDialogState" @onAddLock="addLock" />
    <LockTokenEditDialog :state="editDialogState" @onEditLock="onEditLock" />
    <WaitingDialog
      :state="waitingState"
      :contentText="dialogData.waiting.contentText"
      @closeWaiting="waitingState = false"
    />
    <SuccessDialog
      :state="successState"
      :contentText="dialogData.success.contentText"
      @closeSuccess="successState = false"
    />
    <ErrorDialog
      :state="errorState"
      :titleText="dialogData.error.titleText"
      :contentText="dialogData.error.contentText"
      @closeError="errorState = false"
    />
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import LockTokenAddDialog from '@/components/dialogs/lockTokens/LockTokenAddDialog'
import LockTokenEditDialog from '@/components/dialogs/lockTokens/LockTokenEditDialog'
import fixedNumberHelper from '@/helpers/fixedNumberHelper.js'
import WaitingDialog from '@/components/dialogs/WaitingDialog'
import SuccessDialog from '@/components/dialogs/SuccessDialog'
import ErrorDialog from '@/components/dialogs/ErrorDialog'
const approveDialogData = {
  waiting: {
    contentText: 'approve',
  },
  success: {
    contentText: 'approved',
  },
  error: {
    contentText: 'approved',
    titleText: 'approving',
  },
}
const lockDialogData = {
  waiting: {
    contentText: 'lock',
  },
  success: {
    contentText: 'locked',
  },
  error: {
    contentText: 'locked',
    titleText: 'locking',
  },
}

export default {
  components: {
    LockTokenAddDialog,
    LockTokenEditDialog,
    WaitingDialog,
    SuccessDialog,
    ErrorDialog,
  },
  computed: {
    ...mapState('auth', ['account', 'lockHandler']),
    ...mapState('lockTokens', ['tokenLocks', 'userTokenInfo', 'selectedTokenContract', 'tokenFeePercent']),
    ...mapGetters('lockTokens', ['totalDebit', 'maxDebit', 'remainPercent']),
    finalTotalDebit() {
      return fixedNumberHelper.divUnsafe(this.totalDebit, this.remainPercent)
    },
  },
  props: {},
  data() {
    return {
      locking: false,
      approving: false,
      addDialogState: false,
      editDialogState: false,
      lockState: false,
      isApproved: false,
      dialogData: lockDialogData,
      waitingState: false,
      successState: false,
      errorState: false,
    }
  },
  methods: {
    ...mapActions('lockTokens', [
      'setNewLock',
      'removeSelectedLock',
      'doubleSelectedLock',
      'editLock',
      'resetTokenLocks',
      'setSelectedTokenLock',
    ]),
    handleDoubleLock(item) {
      if (fixedNumberHelper.gt(fixedNumberHelper.addUnsafe(this.totalDebit, item.amount), this.maxDebit)) {
        this.$alert.error(`You do not have enough ${this.userTokenInfo.name} for this transaction.`)
        return
      }
      this.doubleSelectedLock(item)
    },
    addLock(data) {
      this.setNewLock(data)
    },
    openLockEdit(data) {
      this.setSelectedTokenLock(data)

      setTimeout(() => {
        this.editDialogState = !this.editDialogState
      }, 100)
    },
    onEditLock(data) {
      this.editLock(data)
    },
    async onLock() {
      try {
        if (!this.tokenLocks.length) {
          this.$alert.error('Need to add a lock at least')
          return
        }
        this.dialogData = lockDialogData
        this.waitingState = true
        this.locking = true
        await this.lockHandler.handleLock(this.userTokenInfo, this.tokenLocks, this.remainPercent)
        this.$emit('lockDone')
        this.resetTokenLocks()
        // this.$alert.success('lock token from contract successfully')
        this.waitingState = false
        this.successState = true
      } catch (error) {
        console.error(error)
        // this.$alert.error(error.message)
        this.errorState = true
        this.waitingState = false
        this.locking = false
      } finally {
        this.locking = false
      }
    },
    async onApproveContract() {
      try {
        this.dialogData = approveDialogData
        this.waitingState = true
        this.approving = true
        await this.lockHandler.approveContract(this.selectedTokenContract)
        this.isApproved = await this.lockHandler.approvedContract(this.selectedTokenContract)
        // this.$alert.success('Approve contract successfully')
        this.successState = true
        this.waitingState = false
      } catch (error) {
        console.error(error)
        // this.$alert.error(error.message)
        this.errorState = true
        this.waitingState = false
        this.approving = false
      } finally {
        this.approving = false
      }
    },
  },
  async created() {
    if (this.selectedTokenContract) {
      this.isApproved = await this.lockHandler.approvedContract(this.selectedTokenContract)
    }
  },
  filters: {
    shortOwmerAddress(ownerAddress, account) {
      if (!account || !ownerAddress) return ''
      return account === ownerAddress
        ? 'Me'
        : ownerAddress.substr(0, 6) + '...' + ownerAddress.substr(ownerAddress.length - 4)
    },
  },
}
</script>

<style lang="scss" scoped>
.lockCard {
  border-radius: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}
</style>
