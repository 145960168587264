  <template>
  <v-sheet rounded="xl" class="text-start primary secondary lighten-3 pa-6 mt-6">
    <v-row>
      <v-col cols="12" md="6" class="pb-0">
        <p class="text-caption mb-2">Date</p>
        <v-text-field
          v-model="endEmissionDate"
          type="date"
          :rules="[$rules.required, $rules.date]"
          dense
          outlined
          rounded
        />
      </v-col>
      <v-col cols="12" md="6" class="pb-0">
        <p class="text-caption mb-2">Hour</p>
        <v-text-field v-model="endEmissionHour" type="time" :rules="[$rules.required]" dense outlined rounded />
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
import { mapState } from 'vuex'
import moment from 'moment'
export default {
  components: {},
  props: {
    editState: Boolean,
  },
  data() {
    return {
      endEmissionDate: '',
      endEmissionHour: '',
      co: '234124',
    }
  },
  computed: {
    ...mapState('lockTokens', ['selectedTokenLock']),
  },
  methods: {
    getData() {
      return {
        startEmission: 0,
        endEmission: Date.parse(new Date(this.endEmissionDate + ' ' + this.endEmissionHour)) / 1000,
      }
    },
    resetDefault() {
      const startEmission = this.selectedTokenLock.startEmission
      if (startEmission === 0) {
        const endEmission = this.selectedTokenLock.endEmission
        this.endEmissionDate = moment.unix(endEmission).format('YYYY-MM-DD')
        this.endEmissionHour = moment.unix(endEmission).format('HH:mm')
      }
    },
  },
  watch: {
    editState: {
      handler(editState) {
        if (editState) {
          this.resetDefault()
        }
      },
      immediate: true,
    },
  },
}
</script>
