<template>
  <div>
    <v-card
      outlined
      class="d-flex flex-column align-center background-card pa-6 pb-4"
      style="color: black"
      v-if="state"
    >
      <div class="d-flex">
        <v-icon class="mr-2" color="black">mdi-lock-outline</v-icon>
        <div class="text-h5 text-center font-weight-medium">
          <span style="word-break: break-all">{{ totalLockedToken }}</span> {{ userTokenInfo.name }} Locked
        </div>
      </div>
      <div class="grey--text text-sm text-caption text-center">{{ getLockedTokenPercent }}% locked of Total supply</div>
      <div class="d-flex mt-2">
        <v-btn text class="black--text" @click="showLocks">
          <v-icon class="mr-2" color="black" small>mdi-eye</v-icon>
          <span class="text-body-1 font-weight-medium">Show locks</span>
        </v-btn>
      </div>
    </v-card>
    <v-card v-else outlined class="d-flex flex-column align-center pa-6" rounded="lg">
      <v-img contain :src="require(`@/assets/no-lock.svg`)" transition="scale-transition" max-width="51" />
      <div class="text-medium mt-5">No lock for this token</div>
    </v-card>
  </div>
</template> 
<script>
import { mapState, mapGetters } from 'vuex'
import { FIXEDNUMBER_100, FIXEDNUMBER_0 } from '@/constants/index'
import fixedNumberHelper from '@/helpers/fixedNumberHelper.js'

export default {
  data() {
    return {
      state: true,
    }
  },
  computed: {
    ...mapState('auth', ['account', 'lockHandler']),
    ...mapState('lockTokens', ['tokenLocks', 'userTokenInfo', 'tokenAddress']),
    ...mapGetters('lockTokens', ['totalLockedToken']),
    getLockedTokenPercent() {
      if (!this.userTokenInfo.totalSupply || this.userTokenInfo.totalSupply.isZero()) return FIXEDNUMBER_0
      else {
        return fixedNumberHelper.divUnsafe(
          this.totalLockedToken,
          fixedNumberHelper.mulUnsafe(this.userTokenInfo.totalSupply, FIXEDNUMBER_100)
        )
      }
    },
  },
  methods: {
    showLocks() {
      this.$router.push('/locker/tokenLocks/' + this.tokenAddress)
    },
  },
}
</script>
<style scoped>
.background-card {
  background: url('../../assets/lock-bg.svg');
  background-size: cover;
}
</style>
